<template>
  <div>
    <div class="warp-login-bg">
      <div class="warp-login-box">
        <div class="box-header">
          <div class="box-title">用户登录</div>
        </div>
        <div class="box-content">
          <div class="box-content-btn">
            <!-- <div class="login" @click="quickLogin">
              <img src="../../assets/image/quickLogin.png" />
              <span class="text-group_1-0">快速登录</span>
            </div> -->
            <div class="code" @click="codeLogin">
              <img src="../../assets/image/icon_sec-code.png" />
              <span class="text-group_1-1">手机验证码</span>
            </div>
            <div class="user" @click="userLogin">
              <img src="../../assets/image/icon_code.png" />
              <span class="text-group_1-2">账号登陆</span>
            </div>
          </div>
          <div class="box-content-register">
            <button @click="userRegister" class="box-content-register-btn">
              <span class="box-content-span">账号注册</span>
            </button>
          </div>
          <!-- <div class="rule">
            <div class="rule-content">
              <van-checkbox
                shape="square"
                v-model="ruleCheckbox"
                icon-size="16"
                class="rule-check"
              ></van-checkbox>
              <div class="rule-agreement">
                <span>我已阅读并同意</span>
                <span @click="userProcotol">用户协议</span>
                <span>和</span>
                <span @click="privacyPolicy">隐私政策</span>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant';
import { setLocalStorage } from '@/util/userLoginFn';
import { checkInviteCode } from '@/service/moudules/platformApi';
export default {
  data() {
    return {
      setLocalStorage,
      checkInviteCode,
      ruleCheckbox: false,
      inviteCode: '',
    };
  },
  methods: {
    userProcotol() {
      this.$router.push({
        path: '/userProcotol',
      });
    },
    privacyPolicy() {
      this.$router.push({
        path: '/privacyPolicy',
      });
    },
    quickLogin() {
      if (this.inviteCode == '') {
        Toast.fail('您没有邀请码或邀请码有误,请确定链接无误后再次登录');

        return false;
      }
      this.$router.push({
        path: '/quickLogin',
      });
    },
    codeLogin() {
      if (this.inviteCode == '') {
        Toast.fail('您没有邀请码或邀请码有误,请确定链接无误后再次登录');

        return false;
      }
      this.$router.push({
        path: '/phone',
      });
    },
    userLogin() {
      this.$router.push({
        path: '/account',
      });
    },
    userRegister() {
      try {
        if (this.inviteCode == '') {
          Toast.fail('您没有邀请码或邀请码有误,请确定链接无误后再次登录');

          return false;
        }
        this.$router.push({
          path: '/register',
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
  async created() {
    console.log(this.$route, 'route');

    this.inviteCode = this.$route.query.inviteCode;
    let params = {
      inviteCode: this.inviteCode,
    };

    let { code, data, msg } = await checkInviteCode(params);

    if (msg === 'false') {
      Toast.fail('邀请码有误,请检查~');
      this.setLocalStorage('inviteCode', '');
      return false;
    }

    this.setLocalStorage('inviteCode', this.inviteCode);
    // if (this.inviteCode === undefined) {
    //   this.setLocalStorage('inviteCode', '');
    //   return false;
    // }
    // if (this.inviteCode || this.inviteCode !== '') {
    //   this.setLocalStorage('inviteCode', this.inviteCode);
    // }
  },
};
</script>
<style lang="less" scoped>
.warp-login-bg {
  height: 100vh;
  background-image: url('../../assets/image/blogin-bg.jpg');
  background-position: 50% 50%;
  background-size: cover;
  .warp-login-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -10%);
    width: 345px;
    height: 292px;
    background: #fff;
    box-shadow: 0px 2px 16px 0px rgba(12, 28, 65, 0.2);
    border-radius: 11px;
    // border: 2px solid;
    // border-image: linear-gradient(
    //     224deg,
    //     rgba(255, 255, 255, 0.5),
    //     rgba(255, 255, 255, 0.33),
    //     rgba(255, 255, 255, 0.08)
    //   )
    //   2 2;
    //  backdrop-filter: blur(6px);
  }
  .box-header {
    display: flex;
    justify-content: center;
    align-items: center;
    .box-title {
      position: relative;
      color: #000;
      font-size: 18px;
      margin-top: 20px;
    }
    .el-icon-question {
      position: absolute;
      top: 23px;
      right: 20px;
      font-size: 18px;
      color: #000;
    }
  }
  .box-content {
    .box-content-btn {
      width: 100%;
      display: flex;
      height: 120px;
      align-items: center;
      justify-content: space-around;
      color: #000;
      .login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        object-fit: contain;
        & img {
          width: 50px;
          height: 50px;
        }
      }
      .code {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & img {
          width: 50px;
          height: 50px;
        }
      }
      .user {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & img {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
  .box-content-register {
    .box-content-register-btn {
      border-radius: 23px;
      border: 2px solid;
      border-color: #815fe7;
      margin-top: 24px;
      padding: 9px 110px 8px 110px;
      .box-content-span {
        overflow-wrap: break-word;
        color: rgba(115, 81, 233, 1);
        font-size: 18px;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        white-space: nowrap;
        line-height: 25px;
      }
    }
  }
  .rule {
    margin-top: 20px;
    .rule-content {
      display: flex;
      justify-content: center;
      align-items: center;
      .rule-check {
        margin-right: 10px;
      }
      .rule-agreement {
        color: #000;
        font-size: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Medium;

        & :nth-child(2) {
          color: #815fe7;
          font-size: 14px;
          font-size: 14px;
          font-family: PingFang-SC-Medium;
        }
        & :nth-child(4) {
          color: #815fe7;
          font-size: 14px;
          font-size: 14px;
          font-family: PingFang-SC-Medium;
        }
      }

      .policy {
      }
    }
  }
}
</style>
